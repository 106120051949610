<template>
    <div class="detail" v-if="info">
        <div class="title">{{ info.title }}</div>
        <div class="time">{{ info.writeAt }}</div>
        <div class="content" v-html="info.content"></div>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            info: null,
            id: ""
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.info = await this.$http.get(`${this.url}/${this.id}`);
        }
    }
};
</script>

<style lang="less" scoped>
.detail {
    .title {
        margin-bottom: 10px;
        opacity: 0.9;
        text-align: left;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        line-height: 32px;
    }
    .time {
        text-align: left;
        margin-bottom: 30px;
        height: 20px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.3);
        line-height: 20px;
    }
    .content {
        margin-bottom: 40px;
        text-align: left;
        text-indent: 2em;
    }
}
</style>
<style>
.content img {
    max-width: 100%;
}
</style>
